import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import AgentForm from './components/forms/AgentForm.vue'
import AgentList from './components/agents/AgentList.vue'

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        { path: '/home', component: AgentForm },
        { path: '/agents', component: AgentList }
    ],
    linkActiveClass: 'active'
});

const app = createApp(App);

app.use(router);

app.mount('#app');
