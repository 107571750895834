<template>
    <div class="row justify-content-center align-items-center">
        <h1>New Agent</h1>
        <form class="w-75 p-3" @submit.prevent="submitForm">
            <div class="form-group">
                <label>First Name</label>
                <input type="text" v-model.trim="fname" class="form-control" placeholder="First Name" required>
            </div>
            <div class="form-group">
                <label>Last Name</label>
                <input type="text" v-model.trim="lname" class="form-control" placeholder="Last Name" required>
            </div>
            <div class="form-group">
                <label>Email Address</label>
                <input type="email" v-model.trim="email" class="form-control" placeholder="jane@doe.com" required>
            </div>
            <div :class="['form-group']">
                <h4 class="p-1 m-2">Licensed States</h4>

                <div class="form-check form-check-inline">
                    <input type="checkbox" class="form-check-input" id="mn" value="mn" v-model="states">
                    <label for="mn" class="form-check-label">MN</label>
                </div>

                <div class="form-check form-check-inline">
                    <input type="checkbox" class="form-check-input" id="wi" value="wi" v-model="states">
                    <label for="wi" class="form-check-label">WI</label>
                </div>

                <div class="form-check form-check-inline">
                    <input type="checkbox" class="form-check-input" id="other" value="other" v-model="states">
                    <label for="other" class="form-check-label">Other</label>
                </div>
            </div>
            <div v-if="invalidIput" class="p-3 m-3">
                <p style="color: red; font-style: italic;">Some input seems to be invalid. Please check data provided and continue.</p>
            </div>
            <div class="p-2">
                <button class="btn btn-outline-success m-2">Submit</button>
            </div>
        </form>
        <AgentList></AgentList>
    </div>
</template>

<script>
import axios from 'axios';
import AgentList from '../agents/AgentList.vue';

export default {
    data() {
        return {
            fname: "",
            lname: "",
            email: "",
            states: [],
            invalidIput: false,
            error: ""
        };
    },
    methods: {
        submitForm() {
            if (this.states.length === 0) {
                this.invalidIput = true;
                return;
            }
            this.invalidIput = false;
            axios.post("https://vue-http-demo-a875b-default-rtdb.firebaseio.com/agents.json", {
                firstName: this.fname,
                lastName: this.lname,
                email: this.email,
                states: this.states
            }).catch((error) => {
                console.log(error);
                this.error = "Something went wrong! Try again later.";
            });
            this.fname = "";
            this.lname = "";
            this.email = "";
            this.states = [];
        }
    },
    components: { AgentList }
}
</script>

<style scoped>
    .invalid {
        border: 0.2em solid red;
    }
</style>