<template>
    <div>
        <h1>Agents</h1>
        <ul v-if="agentList.length > 0" class="row justify-content-center align-items-center">
            <AgentItem
                v-for="agent in agentList"
                :key="agent.id"
                :firstName="agent.firstName"
                :lastName="agent.lastName"
                :email="agent.email"
                :licensedStates="agent.states"
            ></AgentItem>
        </ul>
        <div v-else-if="error">
            <p class="invalid-feedback">{{ error }}</p>
        </div>
        <div v-else-if="isLoading">
            <p class="valid-feeback">Loading Agents...</p>
        </div>
        <div v-else-if="!error && !isLoading && agentList.length === 0">
            <p class="valid-feedback">There seems to not be any data present in the database. Start filling it!</p>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import AgentItem from './AgentItem.vue';

export default {
    components: { AgentItem },
    data() {
        return {
            agentList: [],
            isLoading: false,
            error: false,
            timer: ''
        };
    },
    mounted() {
        this.getAgents();
    },
    created() {
        this.timer = setInterval(() => {
            console.log('Fetching an updating agent list...');
            this.getAgents();
        }, 10000);
    },
    beforeUnmount() {
        this.cancelAutoUpdate();
    },
    methods: {
        getAgents() {
            this.isLoading = true;

            axios.get("https://vue-http-demo-a875b-default-rtdb.firebaseio.com/agents.json").then(response => {
                const results = [];
                for (const id in response.data) {
                    results.push({
                        id: id,
                        firstName: response.data[id].firstName,
                        lastName: response.data[id].lastName,
                        email: response.data[id].email,
                        states: response.data[id].states
                    });
                }
                this.agentList = results;
                this.isLoading = false;
                this.error = false;
            }).catch((error) => {
                this.isLoading = false;
                console.log(error);
                this.error = error;
            });
        },
        cancelAutoUpdate() {
            clearInterval(this.timer);
        }
    }
}
</script>

<style>
    
</style>