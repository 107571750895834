<template>
    <div class="card p-3 m-3 list-unstyled w-50">
        <li>
            <h3>{{ fullName }}</h3>
            <p>{{ email }}</p>
            <p>{{ licensedStates }}</p>
        </li>
    </div>
</template>

<script>
export default {
    props: [
        'firstName',
        'lastName',
        'email',
        'licensedStates'
    ],
    computed: {
        fullName() {
            return this.firstName + ' ' + this.lastName;
        }
    }
}
</script>

<style scoped>
.card{
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    padding: 14px 80px 18px 36px;
    cursor: pointer;
}

.card:hover{
     transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}
</style>