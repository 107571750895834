<template>
  <the-navigation></the-navigation>
  <main>
    <img alt="Vue logo" src="./assets/logo.png">
    <RouterView></RouterView>
  </main>
</template>

<script>
import TheNavigation from './components/nav/TheNavigation.vue';

export default {
  name: 'App',
  data() {
    return {
      agentList: [
        {
          firstName: 'Tyler Tyler',
          lastName: 'Iverson',
          email: 'tyler@test.com',
          licensedStates: ['MN', 'WI']
        },
        {
          firstName: 'Jane',
          lastName: 'Doe',
          email: 'jane@test.com',
          licensedStates: ['MN']
        }
      ]
    };
  },
  provide() {
    return {
      agentList: this.agentList
    };
  },
  components: {
    TheNavigation
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
